.ui-btn {
  margin-top: 25px;
}
.uploader {
  margin-top: 3px;
}
div.uploader img {
  width: 100px;
  height: 80px;
  margin: 1px;
  border: solid 1px #d9d9d9;
  vertical-align: top;
}

.paste-uploader {
  margin-bottom: 2px;
  width: 81%;
}

.paste-uploader-focused {
  margin-bottom: 2px;
  width: 81%;
  background-color: #e6f7ff;
}

.paste-uploader-icon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  padding-bottom: 5px;
}

.normal-img {
  width: 80px;
  height: 60px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
}

.selected-img {
  width: 80px;
  height: 60px;
  cursor: pointer;
  border: 2px solid #1890ff;
}

.header-img-list {
  width: 100%;
  justify-content: center;
  max-width: 1000;
  border-bottom: 1px solid rgb(240 240 240);
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-top: -10px;
}

.header-img-item {
  display: inline-flex;
  padding: 3px;
}

.middle-img-download {
  height: 36px;
}

.middle-img-download a {
  float: left;
}

.middle-img-download button {
  float: right;
}

.contents-img {
  text-align: center;
}

.btn-fileupload {
  width: 110px;
  margin-left: 10px;
  height: 29px;
}

.progress {
  float: left;
  text-align: right;
  display: none;
}

.progress-ing {
  display: block;
}

.progress img {
  display: block;
  cursor: pointer;
}

.progress div {
  height: 3px;
  background-color: #ff0000;
}

.previewarea-items {
  float: left;
  text-align: right;
  margin-left: 2px;
  margin-right: 2px;
}

.previewarea-items img {
  display: block;
  cursor: pointer;
}

.fixed-col {
  background-color: #fafafa;
}

.tui-image-editor-container .tui-image-editor-controls {
  background-color: transparent !important;
  z-index: 10 !important;
  bottom: -4px !important;
  width: auto !important;
}

.tui-image-editor-menu {
  position: absolute;
  bottom: 19px !important;
  left: 10px;
  z-index: 10 !important;
}

.tui-image-editor-container .tui-image-editor-submenu {
  bottom: -64px !important;
}

.tui-image-editor-main {
  display: inline-block !important;
}

.auth-container {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.auth-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 520px;
}
.success-card {
  border-left: 5px solid #4CAF50;
}
.error-card {
  border-left: 5px solid #f44336;
}

.message {
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  white-space: pre-wrap;
}

.success {
  color: #2e7d32;
}

.error {
  color: #c62828;
}
