@import url("_typography.css");

.ant-layout-header {
  background: #fff;
  padding: 0;
  height: 80px;
  box-shadow: 1px 1px 10px 1px #d9d9d9;
}

/* Table에 sorter와 filter icon 간격 */
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: 0;
}

.ant-alert {
  width: 200px;
  text-align: center;
}

.Header-logo {
  left: 20px;
  font-size: 20px;
  font-weight: 900;
}

.Header-icn {
  right: 20px;
}

/* Tooltip button */
.tt-btn {
  padding-right: 2px;
}

.memo {
  text-align: center;
}

/* 일감 번호, 일감 정보 */
.fixed-col {
  background-color: #fafafa;
}
.firist-col {
  background-color: #ffffff;
}

/* 진행 상태 
.fixed-col-color {
  background-color: #e6f7ff;
}
*/
.ant-table-thead th.ant-table-column-sort {
  background: #fafafa;
}

td.ant-table-column-sort {
  background: transparent;
}

/* sorting시 색 바뀜-일감 번호, 일감 정보 */
td.ant-table-column-sort.fixed-col {
  background: #fafafa !important;
}

td.ant-table-column-sort.fixed-col-color {
  background: #e6f7ff !important;
}

.btn-new:hover {
  background-color: #1890ff !important;
  color: #fff !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  /* border: 1px solid blue; */
  padding: 8px 10px;
  width: 150px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  display: flex !important;
  justify-content: space-between !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td:not(:first-child) {
  background: #efef !important;
  /*opacity: .8 !important;*/
  /* font-weight: 500; */
}
.filter-icn {
  line-height: 20px;
}
.btn-table:hover {
  border-color: #fffacc !important;
  background-color: #fffcdd !important;
}

.hide {
  display: none;
}

.anticon .anticon-delete .filter-icn {
  padding-right: 0;
}

.memo-icon {
  cursor: pointer;
  font-size: 18px;
}

.filter-reset:hover {
  background: #40a9ff !important;
  color: #fff !important;
}

.ant-table-cell.ant-table-cell-fix-left > span {
  margin-left: 12px;
}

/* .ant-rate-star-first,
.ant-rate-star-second {
  color: white;
} */

.priorityColor1 {
  color: #87d068;
}
.priorityColor2 {
  color: #ffbb3d;
}
.priorityColor3 {
  color: #f50;
}

.tdText {
  float: left;
}
.tdSelect {
  text-align: right;
  color: #cccccc;
}

.btn-list {
  color: "#40a9ff";
  box-shadow: "1px 1px 2px #40a9ff";
}

.dateNear {
  color: #177cff;
  margin-top: 14px;
}
.dateOver {
  color: #f5222d;
  margin-top: 14px;
}
.f-left {
  float: left;
}
.ta-right {
  text-align: right;
}
.w-100 {
  width: 100%;
}
.f-8 {
  font-size: 8px;
}
.mr-0 {
  margin-right: 0;
}
.updated {
  color: #f5222d;
  font-size: 20px;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}
.pr-20 {
  padding-right: 20px;
}
.mouseoverEffect:hover {
  background-color: #efefef;
}
/* 테이블 상세보기 컬럼 너비 및 배경색 지정 */
.ant-table-expand-icon-col {
  width: 19px;
}
.tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row > td {
  background-color: white;
}
.tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background-color: white;
}
/* 테이블 TR 높이 고정 */
.ant-table-tbody > tr > td {
  height: 42px;
}
.ant-table-thead > tr > th {
  height: 42px;
}
.ant-table-tbody > tr > td:first-child {
  padding: 0 0px;
}
/* 테이블 TR 여백 조정 */
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 0 8px;
}

/* 일감 제목 마우스 오버시 색상 지정 */
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #006eff;
  margin: 5px 0;
}
a.ant-typography,
.ant-typography a {
  color: #006eff;
}
/* 테이블 검색 필터 영역에 스크롤 생기지 않도록 늘림 */
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 500px;
}
/* 테이블 데이터가 없는 경우 tbody완전 삭제 */
/* .ant-table-placeholder {
  display: none;
} */
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
/* 테이블 피보팅 열기 닫기 영역 */
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.66667;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  background-color: #fafafa;
  border: 0;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 2px 8px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.66667;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  background-color: #e9f5fe;
}
/* 테이블 행간 라인 색상 조정 */
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f08f;
  transition: background 0.3s;
}
.row-group-panel {
  display: inline-block;
  font-weight: 500;
}

.icon-gray {
  color: lightgray;
}
.cur-hand {
  cursor: pointer;
}

.color-picker {
  height: 100%;
  margin-left: -8px;
  padding-right: 100px;
}
.icon-picker {
  color: gray;
  padding-left: 10px;
  padding-top: 14px;
}
.color-tag {
  width: 32px;
  height: 100%;
  margin-left: -8px;
  text-align: center;
}
.text-tag {
  padding-top: 6px;
  font-weight: 800;
  font-size: 6pt;
}
.text-tag1 {
  padding-top: 5px;
  font-weight: 800;
  font-size: 14pt;
}
.text-tag2 {
  padding-top: 6px;
  font-weight: 800;
  font-size: 12pt;
}
.text-tag3 {
  padding-top: 10px;
  font-weight: 800;
  font-size: 8pt;
}
.text-tag4 {
  padding-top: 4px;
  font-weight: 800;
  font-size: 9pt;
}
.text-tag5 {
  padding-top: 4px;
  font-weight: 800;
  font-size: 6pt;
}

.color-picker-container {
  width: 252px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-bottom: -14px;
}

.color-picker-container > span > div {
  width: 28px;
  height: 28px;
  margin-right: 14px;
  margin-bottom: 14px;
  transform: scale(1);
  transition: transform 100ms ease 0s;
}
.color-picker-item {
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  transition: box-shadow 100ms ease 0s;
  text-align: center;
}
.color-picker-item:hover {
  transform: scale(1.1);
}
.pt-3 {
  padding-top: 3px;
}

.activeTableRow {
  /*outline: solid 2px orange;*/
  background-color: rgb(201, 252, 201);
}

span.uploader img {
  width: 100px;
  height: 80px;
  margin: 1px;
  border: solid 1px #969090;
  vertical-align: top;
}

.paste-uploader {
  margin-bottom: 2px;
}

.paste-uploader-focused {
  margin-bottom: 2px;
  background-color: #e6f7ff;
}

.paste-uploader-icon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  padding-bottom: 5px;
}

.normalImg {
  width: 80px;
  height: 60px;
  cursor: "pointer";
  border: 1px solid gray;
}

.selectedImg {
  width: 80px;
  height: 60px;
  cursor: "pointer";
  border: 2px solid #1890ff;
}
.activeTableRow {
  /*outline: solid 2px orange;*/
  background-color: rgb(201, 252, 201);
}

/* bada custom */
.ant-menu-root.ant-menu-vertical {
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

a:hover {
  text-decoration: underline !important;
}

.editable-space-list a:hover {
  text-decoration: none !important;

}


.custom-upload-item .ant-upload-list-item-info {
  pointer-events: none !important;
}
.custom-upload-item .ant-upload-list-item-card-actions {
  pointer-events: auto !important;
}

/* 이미지 호버 관련 스타일 */
.image-hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 8px;
}

.custom-upload-item:hover .image-hover-overlay {
  display: flex;
}

.hover-buttons {
  display: flex;
  gap: 4px;
}

.hover-icon-button {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  padding: 0 !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: rgba(255, 255, 255, 0.8) !important;
  border: none !important;
}
.hover-icon-button:hover {
  background: white !important;
}

.delete-button {
  color: #ff4d4f !important;
}

.delete-button:hover {
  color: #ff7875 !important;
}

.preview-button {
  color: #1890ff !important;
}

.preview-button:hover {
  color: #40a9ff !important;
}

.hover-icon-button .anticon {
  font-size: 14px;
}

.editable-space-list .ant-card-bordered:hover {
  background-color: #efef !important;
}
.ant-list-item:hover {
  background-color: #efef 
}

